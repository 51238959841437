import {accessToken} from "../utils/auth";

const baseUrl = process.env.BASE_URL;

export const requester = (path, options = {}) => {
  const {
    method = 'GET',
    headers,
    body,
    ...extraOpts
  } = options;

  const reqOptions = {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...headers
    },
    ...extraOpts
  }

  if (body) {
    reqOptions.body = JSON.stringify(body)
  }

  return fetch(`${baseUrl}${path}`, reqOptions)
};

export const authRequester = (path, options = {}) => {
  const authHeaders = {'Authorization': `Bearer ${accessToken()}`}
  if (options) {
    options.headers = {...(options.headers || {}), ...authHeaders}
  }
  return requester(path, options)
}
import React from 'react';
import {ChakraProvider, theme} from '@chakra-ui/react';
import TransactionsPage from './pages/TransactionsPage';
import {Router} from "@reach/router"
import LazyComponent from "./components/LazyComponent";
import SignUpPage from "./pages/SignUpPage";


function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <SignUpPage path="/"/>
        <LazyComponent Component={TransactionsPage} path="transactions"/>
      </Router>
    </ChakraProvider>
  );
}

export default App;

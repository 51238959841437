import {authRequester} from "./requester";

export const fetchTransactions = () => {
  return authRequester("/transactions")
    .then(response => {
      if (response.status === 401) {
        console.log(response)
      }
      return response.json().then(data => data.objects)
    });
};